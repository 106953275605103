import raf from 'raf';

const debounce = (fn) => {
    let frame;

    return (...params) => {
        if (frame) {
            raf.cancel(frame);
        }

        frame = raf(() => fn(...params));
    };
};

const storeScroll = () => {
    document.documentElement.dataset.scroll = window.scrollY;
};

document.addEventListener('scroll', debounce(storeScroll), { passive: true });

storeScroll();
