/**
 * @param {URL} url
 * @return {URL}
 */
const filterUrl = (url) => {
    for (let [key, value] of url.searchParams.entries()) {
        if ((value.match(/@/g)||[]).length === 1) {
            url.searchParams.delete(key);
        }
    }

    return url;
};

export default filterUrl;
