jQuery(function($){
    $(document).on('click', '[data-support-link]', function(e){
        e.preventDefault();

        var url = $(this).attr('href');
        var name = 'kayako_popup';

        if (typeof window['popups'] == 'undefined') {
            window['popups'] = [];
        }

        var windowObject = {};

        if (typeof window['popups'][name] !== 'undefined' && !window['popups'][name].closed) {
            windowObject = window['popups'][name];
        } else {
            windowObject = window.open(
                url,
                name,
                'height=750,width=600,status=0,scrollbars=1,toolbar=0,location=1,directories=0,resizable=1'
            );
            window['popups'][name] = windowObject;
        }
        windowObject.focus();
    });
});
