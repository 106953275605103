export default class TransformableTables {
    getFirstLevelChildren(tagName, element) {
        const childrenArray = [];

        for (let i = 0; i < element.children.length; i++) {
            if (element.children[i].tagName.toLowerCase() === tagName) {
                childrenArray.push(element.children[i]);
            }
        }

        return childrenArray;
    }

    overwriteTransformableTables() {
        const tables = document.querySelectorAll('.transformable-table.table');
        /* https://developer.mozilla.org/en-US/docs/Web/API/NodeList */
        Array.prototype.forEach.call(tables, (table) => {
            const theads = table.getElementsByTagName('thead');
            Array.prototype.forEach.call(theads, (thead) => {
                const theadChildren = thead.children[0];
                if (typeof theadChildren === 'undefined') {
                    return;
                }

                const ths = this.getFirstLevelChildren('th', theadChildren);
                if (ths.length > 0) {
                    const trs = this.getFirstLevelChildren('tr', table.getElementsByTagName('tbody')[0]);

                    const rowspanIndexes = this.getFirstLevelChildren('td', trs[0]).reduce((array, tr, index) => {
                        if (tr.hasAttributes('rowspan')) {
                            return [...array, index];
                        }

                        return array;
                    }, []);

                    const filteredThs = ths.filter((th, index) => !rowspanIndexes.includes(index));

                    trs.forEach((tr, index) => {
                        const tds = this.getFirstLevelChildren('td', tr);
                        const indexModifier = (this.getFirstLevelChildren('th', tr).length > 0) ? 1 : 0;
                        for (let k = indexModifier; k < ths.length; k++) {
                            const selectedTd = tds[k - indexModifier];
                            if (typeof selectedTd === 'undefined') {
                                continue;
                            }

                            if (index && filteredThs.length) {
                                selectedTd.setAttribute('data-title', filteredThs[k].innerText);
                            } else {
                                selectedTd.setAttribute('data-title', ths[k].innerText);
                            }
                        }
                    });
                }
            });
        });
    }
}

const transformableTables = new TransformableTables();
document.addEventListener('DOMContentLoaded', () => {
    transformableTables.overwriteTransformableTables();
});
