import 'url-polyfill';

class TrackingUrlProvider {
    /**
     * @param {function} filterUrl
     */
    constructor(filterUrl) {
        this.filterUrl = filterUrl;
    }

    /**
     * @param {string} currentUrl
     * @param {string|null} referrerUrl
     * @returns {string}
     */
    getUrl(currentUrl, referrerUrl = null) {
        const uri = new URL(currentUrl);

        if (referrerUrl !== null && referrerUrl.length > 0) {
            try {
                const referrer = new URL(referrerUrl);
                const filteredUrl = this.filterUrl(referrer);

                uri.hash = encodeURIComponent(filteredUrl.toString());
            } catch (error) {
                // provided referrer URL is invalid
            }
        }

        uri.host = 'tracking.paysera.com';
        uri.protocol = 'https';

        return this.filterUrl(uri).toString();
    }
}

export default TrackingUrlProvider;
