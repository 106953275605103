import 'core-js';
import '@babel/polyfill';
import 'lazysizes';
import '@assetsJsModule/logger';
import './scroll-position';
import './instagram';
import './tracking';

require("@root/node_modules/bootstrap/dist/js/bootstrap.js");
require("@root/node_modules/jquery-cycle2/build/jquery.cycle2.js");
require("@root/node_modules/jquery-cycle2/src/jquery.cycle2.carousel.js");
require("@root/assets/js/module/paysera-cycle-carousel/paysera-cycle-carousel.js");
require("imports-loader?define=>false!imports-loader?exports=>false!@root/node_modules/select2/dist/js/select2.js");
require("@EvpThemeBundle/Resources/public/js/modernizr.js");
require("@EvpThemeBundle/Resources/public/js/kayako.js");
require("@EvpThemeBundle/Resources/public/js/script.js");
require("@EvpThemeBundle/Resources/public/js/transformable-table.js");
