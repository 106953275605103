document.addEventListener(
    'DOMContentLoaded',
    () => {
        if (document.getElementsByClassName('instagram-media').length > 0) {
            const script = document.createElement('script');
            script.src = 'https://www.instagram.com/embed.js';
            document.body.appendChild(script);
        }
    }
);
