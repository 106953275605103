import provider from '@root/app/Resources/assets/tracking';
import hasCookieCategoryConsent from '@PayseraServiceAgreementBundle/Resources/assets/js/service/hasCookieCategoryConsent';
import { CATEGORY_STATISTICS } from '@PayseraServiceAgreementBundle/Resources/assets/js/config/constants';
import isConsentRelevant from '@PayseraServiceAgreementBundle/Resources/assets/js/service/isConsentRelevant';

const addTrackingIframe = () => {
    const frame = document.createElement('iframe');
    frame.setAttribute('src', provider.getUrl(window.location.href, document.referrer));
    frame.setAttribute('class', 'hidden');

    document.addEventListener('DOMContentLoaded', () => {
        document.body.appendChild(frame);
    });
};

const shouldAppendIframe = () => {
    if (hasCookieCategoryConsent(CATEGORY_STATISTICS)) {
        return true;
    }
    return !isConsentRelevant();
};

if (shouldAppendIframe()) {
    addTrackingIframe();
}

export default addTrackingIframe;
