jQuery(function($){
    $.fn.carousel.Constructor.TRANSITION_DURATION = 2000;

    if ($('.cycle-carousel').data('cyclePayseraMiddleOffset')
        && $('.cycle-carousel').data('cyclePayseraMiddleOffset') > 0
    ) {
        const setSlideStyles = slide => {
            slide.addClass('active-slide-style');
            slide.next().addClass('bigger-slide');
            slide.prev().addClass('bigger-slide');
        }

        setSlideStyles($('.cycle-carousel .cycle-slide-active'));

        $('.cycle-carousel').on('cycle-before', (event, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag) => {
            $('.active-slide-style').each((index, slide) => {
                $(slide).removeClass('active-slide-style');
            })

            $('.bigger-slide').each((index, slide) => {
                $(slide).removeClass('bigger-slide');
            });

            const clonesOfIncomingSlide = $('.cycle-slide-id-' + $(incomingSlideEl).data('slideId'));
            clonesOfIncomingSlide.each((index, slide) => setSlideStyles($(slide)));
        });
    }

    $('[data-toggle="popover"]').popover();

    $('.js-localization-popover').popover({
        sanitizeFn: function (content) {
            return content;
        }
    });

    $(document).on('click', '[data-print]', function(event){
        event.preventDefault();
        window.print();
    });

    $(document).on('click', '[data-show-menu]', function(event){
        event.preventDefault();
        $('body').addClass('prepare-animation');
        setTimeout(function(){
            $('body').addClass('show');
        }, 1);
    });

    $(document).on('click', '[data-hide-menu]', function(event){
        event.preventDefault();
        $('body').removeClass('show');
        // wait for animation duration
        setTimeout(function(){
            $('body').removeClass('prepare-animation');
        }, 300);
    });

    // scroll to animation
    $(document).on('click', '[data-scroll-to]', function(event) {
        event.preventDefault();
        var $target = $($(this).data('scrollTo'));
        var destination = $target.offset().top;
        destination = $('body').hasClass('layout-front-page') ? destination - $('header').outerHeight() : destination;
        $('html, body').animate({
            scrollTop: destination
        }, 300);
    });
});
